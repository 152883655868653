<template>
  <!-- Infos contact -->
  <div class="col-md-12">
    <!-- Card -->
    <div class="card h-md-100">
      <!-- Card header-->
      <div class="d-flex card-header px-6">
        <div class="card-title m-0">
          <h3 class="fw-boldest m-0">Informations</h3>
        </div>
        <div class="card-toolbar">
          <router-link
            :to="'/contacts/form/' + contact.id"
            class="btn btn-color-primary"
            >Modifier</router-link
          >
        </div>
      </div>
      <!-- Loader -->
      <Loader :data="contact" />
      <!-- Card body -->
      <div v-if="contact" class="card-body pt-7 h-300px overflow-scroll">
        <div class="d-flex flex-column text-gray-700">
          <div class="mb-3">
            <div class="fw-bolder text-black">Nom du contact</div>
            <div v-if="contact.first_name && contact.first_name" class="pt-2">
              {{ contact.first_name }} {{ contact.last_name }}
            </div>
            <div v-else class="pt-2">Information non fournie</div>
          </div>
          <div class="mb-3">
            <div class="fw-bolder text-black">Email</div>
            <div v-if="contact.email" class="pt-2">
              {{ contact.email }}
            </div>
            <div v-else class="pt-2">Information non fournie</div>
          </div>
          <div class="mb-3">
            <div class="fw-bolder text-black">Téléphone</div>
            <div v-if="contact.phone_number" class="pt-2">
              {{ contact.phone_number }}
            </div>
            <div v-else class="pt-2">Information non fournie</div>
          </div>
          <div class="mb-3">
            <div class="fw-bolder text-black">Second téléphone</div>
            <div v-if="contact.second_phone_number" class="pt-2">
              {{ contact.second_phone_number }}
            </div>
            <div v-else class="pt-2">Information non fournie</div>
          </div>
          <div class="mb-3">
            <div class="fw-bolder text-black">Poste</div>
            <div v-if="contact.position" class="pt-2">
              {{ contact.position }}
            </div>
            <div v-else class="pt-2">Information non fournie</div>
          </div>
          <div>
            <div class="fw-bolder text-black">
              Dernière personne à l'avoir contacté
            </div>
            <div v-if="contact.last_followup_author" class="pt-2">
              {{ contact.last_followup_author }}
            </div>
            <div v-else class="pt-2">Information non fournie</div>
          </div>
        </div>
      </div>
      <!-- Card footer -->
      <div class="mt-8"></div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ContactDetails",
  computed: {
    ...mapGetters(["contact"]),
  },
  methods: {
    ...mapActions(["getContact"]),
    showContact(id) {
      this.getContact(id);
    },
  },
};
</script>
