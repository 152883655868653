<template>
  <div class="col-xl-4">
    <!-- Card -->
    <div class="card h-md-100">
      <!-- Card header-->
      <div class="d-flex card-header px-6">
        <div class="card-title m-0">
          <h3 class="fw-boldest m-0">Dates de rappel</h3>
        </div>
        <div class="card-toolbar">
          <button
            type="button"
            class="btn btn-color-primary"
            data-bs-toggle="modal"
            data-bs-target="#modalCallbackDates"
          >
            + Ajouter
          </button>
        </div>
      </div>
      <!-- Loader -->
      <Loader :data="contactCallbackDates" />
      <!-- Card body -->
      <div
        v-if="contactCallbackDates"
        class="card-body pt-7 h-200px overflow-scroll"
      >
        <div
          v-if="contactCallbackDates.count > 0"
          class="d-flex flex-column text-gray-700"
        >
          <div
            v-for="callbackDate in contactCallbackDates.results"
            :key="callbackDate.id"
          >
            <div class="mb-3 d-flex justify-content-between align-items-center">
              <div>
                <span class="fw-bolder text-black">Date : </span>
                <span>{{ callbackDate.value }}</span>
              </div>
              <div>
                <span
                  class="btn svg-icon svg-icon-danger svg-icon-2"
                  @click.prevent="removeCallbackDate(callbackDate.id)"
                >
                  <i
                    class="bi bi-trash-fill text-danger"
                    style="font-size: 1.2rem"
                  ></i>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="pt-2">
          Aucune date de rappel associé à ce contact
        </div>
      </div>
      <!-- Card footer -->
      <div class="mt-8"></div>
    </div>
  </div>
  <!-- Add follow ups modal -->
  <div
    id="modalCallbackDates"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="modalCallbackDatesLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered mw-650px">
      <div class="modal-content">
        <!-- Modal header -->
        <div class="modal-header">
          <h3 class="fw-boldest text-dark fs-1 mb-0">
            Ajouter une date de rappel
          </h3>
          <div
            class="btn btn-icon btn-sm btn-active-icon-primary"
            data-bs-dismiss="modal"
          >
            <span class="svg-icon svg-icon-2x">
              <CloseIcon />
            </span>
          </div>
        </div>
        <!-- Modal body -->
        <div class="modal-body px-lg-12">
          <form>
            <div v-if="users" class="mb-3">
              <label class="required fs-5 fw-bold mb-2">Propriétaire</label>
              <select
                v-if="users.count > 0"
                v-model="form.user"
                class="form-select form-select-solid"
              >
                <option value="">Choisir un propriétaire</option>
                <option
                  v-for="user in users.results"
                  :key="user.id"
                  :value="user.id"
                >
                  {{ user.first_name }} {{ user.last_name }}
                </option>
              </select>
              <select
                v-else
                class="form-select form-select-solid form-select-lg me-4"
              >
                <option value="">Aucun utilisateur</option>
              </select>
            </div>
            <div v-else>
              <Loader :data="contacts" />
            </div>
            <div class="mb-5 fv-row">
              <label
                class="required d-flex align-items-center fs-5 fw-bold mb-2"
              >
                <span>Date</span>
              </label>
              <input
                v-model="form.value"
                type="date"
                class="form-control form-control-solid mb-3"
                rows="3"
              />
            </div>
          </form>
        </div>
        <!-- Modal footer -->
        <div class="modal-footer">
          <button type="button" class="btn btn-light" data-bs-dismiss="modal">
            Fermer
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            @click.prevent="addCallbackDate()"
          >
            Ajouter
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loader from "@/components/Loader";
import CloseIcon from "@/components/icons/CloseIcon";

export default {
  name: "ContactCallbackDates",
  components: {
    Loader,
    CloseIcon,
  },
  props: {
    id_contact: { default: null },
    id_user: { default: null },
  },
  emits: ["refreshData"],
  data() {
    return {
      form: {
        value: "",
        contact_id: this.id_contact,
        user: "",
      },
      callbackDates: null,
    };
  },
  computed: {
    ...mapGetters(["contactCallbackDates"]),
    ...mapGetters(["users"]),
  },
  mounted() {
    this.showContactCallbackDates();
    this.getAllUsers();
  },
  methods: {
    ...mapActions(["postCallbackDate"]),
    ...mapActions(["getContactCallbackDates"]),
    ...mapActions(["getUsers"]),
    ...mapActions(["deleteCallbackDate"]),
    async showContactCallbackDates() {
      this.callBackDates = await this.getContactCallbackDates(this.id_contact);
    },
    async removeCallbackDate(id) {
      await this.deleteCallbackDate(id);
      await this.showContactCallbackDates();
    },
    async addCallbackDate() {
      if (!this.form.user) {
        this.form.user = this.id_user;
      }
      await this.postCallbackDate(this.form);
      await this.showContactCallbackDates();
    },
    getAllUsers() {
      this.getUsers();
    },
  },
};
</script>
