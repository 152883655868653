<template>
  <div class="content fs-6 d-flex flex-column flex-column-fluid mb-12">
    <!-- Header -->
    <div v-if="contact" class="toolbar">
      <div class="container-fluid d-flex flex-stack flex-wrap flex-sm-nowrap">
        <div
          class="d-flex flex-column align-items-start justify-content-center flex-wrap me-2"
        >
          <h1 class="text-dark fw-bolder my-1 fs-2">
            {{ contact.last_name }} {{ contact.first_name }}
          </h1>
        </div>
        <div class="d-flex align-items-center flex-nowrap text-nowrap py-1">
          <star-rating
            :star-size="40"
            :rating="ratingForm.relation_quality"
            @update:rating="setRating"
          ></star-rating>
          <button
            v-if="ratingForm.relation_quality !== 0"
            class="btn btn-color-danger"
            @click.prevent="setRating(0)"
          >
            <i class="bi bi-x-circle-fill" style="font-size: 1.2rem"></i>
          </button>
        </div>
      </div>
    </div>
    <!-- Loader -->
    <Loader :data="contact" />
    <!-- Body -->
    <div v-if="contact" class="post fs-6 d-flex flex-column-fluid">
      <div class="container-fluid">
        <div class="row row-cols-1 row-cols-md-2 row-cols-xl-3 g-5 g-xl-9">
          <ContactDetails />
          <ContactCallbackDates
            v-if="id"
            :id_contact="id"
            :id_user="user_id"
            @refresh-data="showContact(id)"
          />
          <ContactPromoters />
          <ContactFollowUps
            v-if="id"
            :id_contact="id"
            @refresh-data="showContact(id)"
          />
          <ContactPermits v-if="id" :contact-id="id" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { useRoute } from "vue-router";
import Loader from "@/components/Loader";
import ContactDetails from "@/components/contacts/ContactDetails";
import ContactFollowUps from "@/components/contacts/ContactFollowUps";
import ContactPermits from "@/components/contacts/ContactPermits";
import ContactPromoters from "@/components/contacts/ContactPromoters.vue";
import ContactCallbackDates from "@/components/contacts/ContactCallbackDates";
import StarRating from "vue-star-rating";

export default {
  name: "ContactsInfos",
  components: {
    Loader,
    ContactDetails,
    ContactFollowUps,
    ContactPermits,
    ContactPromoters,
    ContactCallbackDates,
    StarRating,
  },
  data() {
    return {
      id: null,
      user_id: null,
      ratingForm: {
        id: "",
        relation_quality: "",
      },
    };
  },
  computed: {
    ...mapGetters(["contact"]),
    ...mapGetters(["userAuth"]),
  },
  mounted() {
    const route = useRoute();
    this.id = route.params.id;
    this.user_id = this.userAuth.user_id;
    this.showContact(route.params.id);
  },
  methods: {
    ...mapActions(["getContact"]),
    ...mapActions(["rateContact"]),
    async showContact(id) {
      await this.getContact(id);
      this.ratingForm.relation_quality = this.contact.relation_quality;
    },
    setRating(rating) {
      this.ratingForm.id = this.id;
      this.ratingForm.relation_quality = rating;
      this.rateContact(this.ratingForm);
    },
  },
};
</script>
