<template>
  <!-- Infos contact -->
  <div class="col-md-12">
    <!-- Card -->
    <div class="card h-md-100">
      <!-- Card header-->
      <div class="card-header px-6">
        <div class="card-title m-0">
          <h3 class="fw-boldest m-0">Promoteur</h3>
        </div>
      </div>
      <!-- Loader -->
      <Loader :data="contact" />
      <!-- Card body -->
      <div v-if="contact" class="card-body pt-7 h-300px overflow-scroll">
        <div v-if="contact.promoter" class="d-flex flex-column text-gray-700">
          <div class="mb-3">
            <div class="fw-bolder text-black">Nom du promoteur</div>
            <div v-if="contact.promoter.name" class="pt-2">
              <router-link :to="'/promoters/' + contact.promoter.id">{{
                contact.promoter.name
              }}</router-link>
            </div>
            <div v-else class="pt-2">Information non fournie</div>
          </div>
          <div class="mb-3">
            <div class="fw-bolder text-black">Nom du directeur</div>
            <div v-if="contact.promoter.director_name" class="pt-2">
              {{ contact.promoter.director_name }}
            </div>
            <div v-else class="pt-2">Information non fournie</div>
          </div>
          <div class="mb-3">
            <div class="fw-bolder text-black">Agence du promoteur</div>
            <div v-if="contact.agency__name" class="pt-2">
              <router-link :to="'/agencies/' + contact.agency.id">{{
                contact.agency__name
              }}</router-link>
            </div>
            <div v-else class="pt-2">Information non fournie</div>
          </div>
          <div class="mb-3">
            <div class="fw-bolder text-black">Adresse de l'agence</div>
            <div v-if="contact.agency" class="pt-2">
              <div v-if="contact.agency.address">
                {{ contact.agency.address.number }}
                {{ contact.agency.address.street }},
                {{ contact.agency.address.zipcode }} -
                {{ contact.agency.address.city }}
              </div>
            </div>
            <div v-else class="pt-2">Information non fournie</div>
          </div>
        </div>
      </div>
      <!-- Card footer -->
      <div class="mt-8"></div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ContactPromoters",
  computed: {
    ...mapGetters(["contact"]),
  },
  methods: {
    ...mapActions(["getContact"]),
    showContact(id) {
      this.getContact(id);
    },
  },
};
</script>
