<template>
  <div v-if="permits">
    <v-select
      v-if="permits"
      v-model="model"
      class="form-control form-control-solid form-control-lg me-6"
      :reduce="(permit) => permit.id"
      :options="arrayPermits"
      label="label"
      :filterable="false"
      placeholder="Permis"
      @option:selected="$emit('selected', model)"
      @search="fetchOptionsPermit"
    />
    <div v-else class="col-lg-10 fv-row">
      <Loader :data="permits" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loader from "@/components/Loader";

export default {
  name: "PermitsSelect",
  components: {
    Loader,
  },
  data: function () {
    return {
      arrayPermits: [],
      model: "",
    };
  },
  computed: {
    ...mapGetters(["permits"]),
  },
  methods: {
    ...mapActions(["getPermitsInSelect"]),
    ...mapActions(["searchPermit"]),
    async getAllPermits() {
      await this.getPermitsInSelect();
      let array = [];
      this.permits.results.forEach((element) => {
        array.push({
          label:
            (element.num_dau ? element.num_dau : "") +
            " " +
            (element.adr_num_ter != null ? element.adr_num_ter : "") +
            " " +
            (element.adr_typevoie_ter != null ? element.adr_typevoie_ter : "") +
            " " +
            (element.localite_dem != null ? element.localite_dem : "") +
            (element.adr_libvoie_ter != null ? element.adr_libvoie_ter : "") +
            ", nb log : " +
            (element.nb_lgt_tot_crees != null ? element.nb_lgt_tot_crees : "") +
            ", nb lls : " +
            (element.nb_lls != null ? element.nb_lls : "") +
            ", Date d'obtention : " +
            (element.date_reelle_autorisation != null
              ? element.date_reelle_autorisation
              : "") +
            ")",
          id: element.id,
        });
      });
      this.arrayPermits = array;
    },
    async fetchOptionsPermit(search, loading) {
      if (search.length) {
        loading(true);
        let array = [];
        await this.searchPermit(search);
        this.permits.results.forEach((element) => {
          array.push({
            label:
              element.num_dau +
              " (Addresse : " +
              element.adr_num_ter +
              " " +
              element.adr_typevoie_ter +
              " " +
              element.adr_libvoie_ter +
              ", Nombre de logements : " +
              element.nb_lgt_tot_crees +
              ", Nombre de logements sociaux : " +
              element.nb_lls +
              ", Date d'obtention : " +
              element.date_reelle_autorisation +
              ")",
            id: element.id,
          });
        });
        this.arrayPermits = array;
        loading(false);
      }
    },
  },
  mounted() {
    this.getAllPermits();
  },
};
</script>
