<template>
  <!-- Suivis avec permis -->
  <div class="col-xl-6">
    <!-- Card -->
    <div class="card h-md-100">
      <!-- Card header-->
      <div class="d-flex card-header px-6">
        <div class="card-title m-0">
          <h3 class="fw-boldest m-0">Suivis avec permis</h3>
        </div>
        <div class="card-toolbar">
          <button
            type="button"
            class="btn btn-color-primary"
            data-bs-toggle="modal"
            data-bs-target="#modalFollowUpsWithPermits"
          >
            + Ajouter
          </button>
        </div>
      </div>
      <!-- Loader -->
      <Loader :data="followUpsWithPermit" />
      <!-- Card body -->
      <div
        v-if="followUpsWithPermit"
        class="card-body pt-7 h-250px overflow-scroll"
      >
        <div
          v-if="followUpsWithPermit.count > 0"
          class="d-flex flex-column text-gray-700"
        >
          <div
            v-for="followUpWithPermit in followUpsWithPermit.results"
            :key="followUpWithPermit.id"
          >
            <div class="mb-3 d-flex justify-content-between">
              <div>
                <div
                  v-if="followUpWithPermit.created"
                  class="fw-bolder text-primary"
                >
                  Date :
                  {{ getMomentDate(followUpWithPermit.created) }}
                </div>
                <div>
                  <span
                    v-if="
                      followUpWithPermit.message && followUpWithPermit.author
                    "
                    >Auteur : {{ followUpWithPermit.author.first_name }}
                    {{ followUpWithPermit.author.last_name }}</span
                  >
                  <span
                    v-if="
                      followUpWithPermit.message &&
                      followUpWithPermit.author == null
                    "
                    >Auteur : aucunes informations</span
                  >
                </div>
                <div>Message : {{ followUpWithPermit.message }}</div>
                <div v-if="followUpWithPermit.permit">
                  Permis :
                  <span v-if="followUpWithPermit.permit.num_dau">{{
                    followUpWithPermit.permit.num_dau
                  }}</span>
                  <span v-else>Information non fournie</span>
                </div>
              </div>
              <div class="d-flex m-3">
                <span
                  class="text-primary me-3"
                  style="cursor: pointer"
                  data-bs-toggle="modal"
                  data-bs-target="#updateModalFollowUps"
                  @click.prevent="
                    addIdPatch(
                      followUpWithPermit.id,
                      followUpWithPermit.message
                    )
                  "
                >
                  Modifier
                </span>
                <span
                  class="text-danger"
                  style="cursor: pointer"
                  @click.prevent="removeFollowUp(followUpWithPermit.id)"
                >
                  Supprimer
                </span>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="pt-2">Aucun suivi avec permis</div>
      </div>
      <!-- Card footer -->
      <div class="mt-8"></div>
    </div>
  </div>
  <!-- Suivis sans permis -->
  <div class="col-xl-6">
    <!-- Card -->
    <div class="card h-md-100">
      <!-- Card header-->
      <div class="d-flex card-header px-6">
        <div class="card-title m-0">
          <h3 class="fw-boldest m-0">Suivis sans permis</h3>
        </div>
        <div class="card-toolbar">
          <button
            type="button"
            class="btn btn-color-primary"
            data-bs-toggle="modal"
            data-bs-target="#modalFollowUpsWithoutPermits"
          >
            + Ajouter
          </button>
        </div>
      </div>
      <!-- Loader -->
      <Loader :data="followUpsWithoutPermit" />
      <!-- Card body -->
      <div
        v-if="followUpsWithoutPermit"
        class="card-body pt-7 h-250px overflow-scroll"
      >
        <div
          v-if="followUpsWithoutPermit.count > 0"
          class="d-flex flex-column text-gray-700"
        >
          <div
            v-for="followUpWithoutPermit in followUpsWithoutPermit.results"
            :key="followUpWithoutPermit.id"
          >
            <div class="mb-3 d-flex justify-content-between">
              <div>
                <div
                  v-if="followUpWithoutPermit.created"
                  class="fw-bolder text-primary"
                >
                  Date :
                  {{ getMomentDate(followUpWithoutPermit.created) }}
                </div>
                <div>
                  <span
                    v-if="
                      followUpWithoutPermit.message &&
                      followUpWithoutPermit.author
                    "
                    >Auteur : {{ followUpWithoutPermit.author.first_name }}
                    {{ followUpWithoutPermit.author.last_name }}</span
                  >
                  <span
                    v-if="
                      followUpWithoutPermit.message &&
                      followUpWithoutPermit.author == null
                    "
                    >Auteur : aucunes informations</span
                  >
                </div>
                <div class="pt-2">
                  Message : {{ followUpWithoutPermit.message }}
                </div>
              </div>
              <div class="d-flex m-3">
                <span
                  class="text-primary me-3"
                  style="cursor: pointer"
                  data-bs-toggle="modal"
                  data-bs-target="#updateModalFollowUps"
                  @click.prevent="
                    addIdPatch(
                      followUpWithoutPermit.id,
                      followUpWithoutPermit.message
                    )
                  "
                >
                  Modifier
                </span>
                <span
                  class="text-danger"
                  style="cursor: pointer"
                  @click.prevent="removeFollowUp(followUpWithoutPermit.id)"
                >
                  Supprimer
                </span>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="pt-2">Aucun suivi sans permis</div>
      </div>
      <!-- Card footer -->
      <div class="mt-8"></div>
    </div>
  </div>
  <!-- Modal add follow up with permits -->
  <div
    id="modalFollowUpsWithPermits"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="modalFollowUpsLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered mw-650px">
      <div class="modal-content">
        <!-- Modal header -->
        <div class="modal-header">
          <h3 class="fw-boldest text-dark fs-1 mb-0">
            Ajouter un suivi avec permis
          </h3>
          <div
            class="btn btn-icon btn-sm btn-active-icon-primary"
            data-bs-dismiss="modal"
          >
            <span class="svg-icon svg-icon-2x">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <rect
                  opacity="0.5"
                  x="6"
                  y="17.3137"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(-45 6 17.3137)"
                  fill="black"
                />
                <rect
                  x="7.41422"
                  y="6"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(45 7.41422 6)"
                  fill="black"
                />
              </svg>
            </span>
          </div>
        </div>
        <!-- Modal body -->
        <div class="modal-body px-lg-12">
          <form>
            <div class="row mb-5">
              <div>
                <label class="fs-5 fw-bold mb-2">
                  <span>Permis lié</span>
                </label>
                <PermitsSelect @selected="selectFollowUpPermitOption" />
              </div>
            </div>
            <div class="mb-5 fv-row">
              <label class="fs-5 fw-bold mb-2">
                <span>Message</span>
              </label>
              <textarea
                v-model="message"
                class="form-control form-control-solid mb-3"
                rows="3"
              ></textarea>
            </div>
          </form>
        </div>
        <!-- Modal footer -->
        <div class="modal-footer">
          <button type="button" class="btn btn-light" data-bs-dismiss="modal">
            Fermer
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            @click.prevent="addFollowUp()"
          >
            Ajouter
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal add follow up without permits -->
  <div
    id="modalFollowUpsWithoutPermits"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="modalFollowUpsLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered mw-650px">
      <div class="modal-content">
        <!-- Modal header -->
        <div class="modal-header">
          <h3 class="fw-boldest text-dark fs-1 mb-0">Ajouter un suivi</h3>
          <div
            class="btn btn-icon btn-sm btn-active-icon-primary"
            data-bs-dismiss="modal"
          >
            <span class="svg-icon svg-icon-2x">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <rect
                  opacity="0.5"
                  x="6"
                  y="17.3137"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(-45 6 17.3137)"
                  fill="black"
                />
                <rect
                  x="7.41422"
                  y="6"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(45 7.41422 6)"
                  fill="black"
                />
              </svg>
            </span>
          </div>
        </div>
        <!-- Modal body -->
        <div class="modal-body px-lg-12">
          <form>
            <div class="mb-5 fv-row">
              <label class="d-flex align-items-center fs-5 fw-bold mb-2">
                <span>Message</span>
              </label>
              <textarea
                v-model="message"
                class="form-control form-control-solid mb-3"
                rows="3"
              ></textarea>
            </div>
          </form>
        </div>
        <!-- Modal footer -->
        <div class="modal-footer">
          <button type="button" class="btn btn-light" data-bs-dismiss="modal">
            Fermer
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            @click.prevent="addFollowUpWithoutPermit()"
          >
            Ajouter
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    id="updateModalFollowUps"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="modalFollowUpsLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered mw-650px">
      <div class="modal-content">
        <!-- Modal header -->
        <div class="modal-header">
          <h3 class="fw-boldest text-dark fs-1 mb-0">Modifier le suivi</h3>
          <div
            class="btn btn-icon btn-sm btn-active-icon-primary"
            data-bs-dismiss="modal"
          >
            <span class="svg-icon svg-icon-2x">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <rect
                  opacity="0.5"
                  x="6"
                  y="17.3137"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(-45 6 17.3137)"
                  fill="black"
                />
                <rect
                  x="7.41422"
                  y="6"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(45 7.41422 6)"
                  fill="black"
                />
              </svg>
            </span>
          </div>
        </div>
        <!-- Modal body -->
        <div class="modal-body px-lg-12">
          <form>
            <div class="mb-5 fv-row">
              <label class="d-flex align-items-center fs-5 fw-bold mb-2">
                <span>Message</span>
              </label>
              <textarea
                v-model="messageUpdate"
                class="form-control form-control-solid mb-3"
                rows="3"
              ></textarea>
            </div>
          </form>
        </div>
        <!-- Modal footer -->
        <div class="modal-footer">
          <button type="button" class="btn btn-light" data-bs-dismiss="modal">
            Fermer
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            @click.prevent="modifyFollowUp()"
          >
            Modifier
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import Loader from "@/components/Loader";
import PermitsSelect from "@/components/selects/PermitsSelect";

export default {
  name: "ContactFollowUps",
  components: {
    Loader,
    PermitsSelect,
  },
  props: {
    id_contact: { default: null },
  },
  emits: ["refreshData"],
  data() {
    return {
      message: "",
      permit: "",
      messageUpdate: "",
      idUpdate: null,
      followUps: null,
      followUpsWithoutPermit: null,
      followUpsWithPermit: null,
    };
  },
  mounted() {
    this.showContactFollowUps();
  },
  methods: {
    ...mapActions(["postFollowUp"]),
    ...mapActions(["getContactFollowups"]),
    ...mapActions(["getContactFollowupsWithPermit"]),
    ...mapActions(["getContactFollowupsWithoutPermit"]),
    ...mapActions(["updateFollowUp"]),
    ...mapActions(["deleteFollowUp"]),
    async showContactFollowUps() {
      this.followUps = await this.getContactFollowups(this.id_contact);
      this.followUpsWithoutPermit = await this.getContactFollowupsWithoutPermit(
        this.id_contact
      );
      this.followUpsWithPermit = await this.getContactFollowupsWithPermit(
        this.id_contact
      );
    },
    addFollowUp() {
      let form = {
        contact: this.id_contact,
        message: this.message,
        permit: this.permit,
      };
      this.postFollowUp(form);
      this.showContactFollowUps();
    },
    addFollowUpWithoutPermit() {
      let form = {
        contact: this.id_contact,
        message: this.message,
      };
      this.postFollowUp(form);
      this.showContactFollowUps();
    },
    addIdPatch(id, message) {
      this.idUpdate = id;
      this.messageUpdate = message;
    },
    modifyFollowUp() {
      let form = {
        id: this.idUpdate,
        message: this.messageUpdate,
      };
      this.updateFollowUp(form);
      this.$emit("refreshData");
      this.showContactFollowUps();
    },
    removeFollowUp(id) {
      this.deleteFollowUp(id);
      this.showContactFollowUps();
    },
    getMomentDate(value) {
      return moment(value).locale("FR").format("DD/MM/YYYY");
    },
    selectFollowUpPermitOption(element) {
      this.permit = element;
    },
  },
};
</script>
